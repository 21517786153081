<template>
    <div>
        <el-popover placement="right" width="300" trigger="hover" v-if="logs.length">
            <div>
              <div class="border-bottom pb-2 scroll">
                 <div class="documentLogs__title border-bottom d-flex align-items-center">
                    <div class="pr-1 avatar-icon">
                        <i class="el-icon-s-custom fs-3"></i>
                    </div>
                    <div>
                        <h4 class="mb-0 text-dark ml-1">Approval users status</h4>
                        <p class="logs-text text-muted ml-1">
                            All approval users status
                        </p>
                    </div>
                  </div>
                    <div v-for="(user, i) in users" :key="'user_' + i" class="mt-1">
                        <div class="logs-avatar">
                            <div class="d-flex" v-if="user && user.user_name">
                                <el-tag class="avatar-text color-6">
                                    {{ getInitialNames(user.user_name) }}
                                </el-tag>
                                <h5>{{ user.user_name }}</h5>
                            </div>
                            <div class="d-flex" v-else-if="user && user.application_user_type">
                                <el-tag class="avatar-text color-6">
                                    {{ getInitialNames(entityNameObj[user.application_user_type]) }}
                                </el-tag>
                                <h5>{{ entityNameObj[user.application_user_type] }}</h5>
                            </div>
                            <div class="d-flex" v-else-if="user && user.company_user_type">
                                <el-tag class="avatar-text color-6">
                                    {{ getInitialNames(allCompanyUserTypesDataObj[user.company_user_type]) }}
                                </el-tag>
                                <h5>{{ allCompanyUserTypesDataObj[user.company_user_type] }}</h5>
                            </div>
                            <div class="d-flex" v-else-if="user && user.user_type === 'LINKEDFIELD'">
                                <el-tag class="avatar-text color-6">
                                  {{ getInitialNames(getBasedOnFieldData(user, i)) }}
                                </el-tag>
                               <h5 v-if="getBasedOnFieldData(user, i)">{{getBasedOnFieldData(user, i)}}</h5>
                            </div>
                            <el-tooltip :content="getUserContent(user)" placement="top">
                                <el-tag class="recipient-status capitalize" :type="getUserAction(user) | getLogStatusType"> <i :class="['iconStyling', getIconClass(user)]"></i>{{
                                    getUserAction(user)
                                    | parseText }}</el-tag>
                            </el-tooltip>
                        </div>
                        <div class="logs-content" v-if="user.approved_at || user.rejected_at">
                            <p class="logs-date">
                                <i class="el-icon-time"></i>
                                <span class="date_time" v-if="user.approved_at">
                                    {{ user.approved_at | globalDateTimeFormat }}
                                </span>
                                <span class="date_time" v-else-if="user.rejected_at">
                                    {{ user.rejected_at | globalDateTimeFormat }}
                                </span>
                            </p>
                        </div>
                        <!-- <div v-if="getUserAction(user) === 'Waiting for approval'" class="resendDiv"> -->
                            <el-button @click="resendEmail(user, i)"
                              type="text" class="resendMail" v-if="!loading && getUserAction(user) === 'Waiting for approval'">
                              Resend
                            </el-button>
                            <div v-if="loading && getUserAction(user) === 'Waiting for approval' && i === selectedUserIndex" class="resendMailLoading">
                              <i class="el-icon-loading"></i>
                               Sending...
                          </div>
                        <!-- </div> -->
                    </div>
                </div>

                <div class="documentLogs__title border-bottom d-flex align-items-center">
                    <div class="pr-1">
                        <i class="el-icon-date fs-4"></i>
                    </div>
                    <div>
                        <h4 class="mb-0 text-dark">Form builder Approval Logs</h4>
                        <p class="logs-text text-muted">
                            All approvers activities on this form
                        </p>
                    </div>
                </div>
                <div class="scroll">
                    <div v-for="(log, i) in logs" :key="'log_' + i" class="mt-1">
                        <div class="logs-avatar">
                            <div class="d-flex">
                                <el-tag class="avatar-text color-6" v-if="log.user_details && log.user_details.name">
                                    {{ getInitialNames(log.user_details.name) }}
                                </el-tag>
                                <h5 v-if="log.user_details && log.user_details.name">{{ log.user_details.name }}</h5>
                            </div>
                            <el-tooltip :content="log.description" placement="top">

                                <el-tag class="recipient-status capitalize" :type="log.action | getLogStatusType">{{
                                    log.action
                                    | parseText }}</el-tag>
                            </el-tooltip>

                        </div>
                        <div class="logs-content">
                            <p class="logs-date">
                                <i class="el-icon-time"></i>
                                <span class="date_time">
                                    {{ log.date | globalDateTimeFormat }}
                                </span>
                            </p>
                        </div>
                    </div>
                    </div>
            </div>
            <i class="el-icon-info" v-if="logs.length" slot="reference"></i>
        </el-popover>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: ["currentFormbuilderData", "allEntitiesData", "allCompanyUserTypesData"],
    data() {
        return {
            logs: [],
            users: [],
            entityNameObj: {},
            allCompanyUserTypesDataObj: {},
            loading: false,
            selectedUserIndex: null,
        }
    },
    computed: {
        ...mapGetters("formbuilderData", [
           "getResendApprovalMailstatus"
        ]),
        getLabel() {
            return userType => {
                if (userType && userType.role) {
                return userType.role.title + "-" + userType.name;
                } else {
                return userType.name;
                }
            };
        },
    },
    mounted() {
        if (this.currentFormbuilderData?.logs) {
            this.logs = [...this.currentFormbuilderData.logs];
        }
        if (this.currentFormbuilderData?.approval_users) {
            this.users = [...this.currentFormbuilderData.approval_users];
        }
        (this.allEntitiesData || []).forEach(e => {
            this.entityNameObj[e._id] = e.name
        });
        (this.allCompanyUserTypesData || []).forEach(e => {
            this.allCompanyUserTypesDataObj[e._id] = this.getLabel(e);
        });
    },
    filters: {
        parseText(value) {
            return value.replace(/_/g, " ").toLowerCase();
        },
        getLogStatusType(status) {
            if (
                status === "APPROVED"
            ) {
                return "success";
            } else if (
                status === "REJECTED"
            ) {
                return "danger";
            } else if (status === 'VIEWED') {
                return "info"
            } else if(status == 'Waiting for approval'){
                return "warning"
            }
            return "";
        },
    },
    methods: {
        async resendEmail(user, index) {
            this.selectedUserIndex = index;
            let formBuilderDataId = this.currentFormbuilderData?._id;
            let formBuilderId = this.currentFormbuilderData?.formbuilders_details_id?._id;
            let allowed_users = [];
            allowed_users.push(user);
            this.loading = true;
            let params = {
                   formbuilder_id: formBuilderId,
                   formbuilder_data_id: formBuilderDataId,
                   allowed_users
            };
            await this.$store.dispatch("formbuilderData/resendApprovalMail", { params });
            if(this.getResendApprovalMailstatus){
                this.$message.success("Mail sent succesfully");
            } else {
                this.$message.error("Error while sending mail");
            }
            this.loading = false;
        },
        getIconClass(user) {
          const status = this.getUserAction(user);
          if (status === "APPROVED") {
            return "el-icon-success";
          } else if (status === "REJECTED") {
            return "el-icon-error";
          } else if (status === "VIEWED") {
            return "el-icon-info";
          } else if (status === "Waiting for approval") {
            return "el-icon-time";
          }
          return "";
        },
        getUserAction(user){
            if(user?.approved_at && user.approved_by){
                return "APPROVED"
            }else if(user?.rejected_at){
                return "REJECTED"
            }else if(this.currentFormbuilderData.approval_status == "REJECTED" || this.currentFormbuilderData.approval_status == "APPROVED"){
                return "Don't require any action"
            }
            return 'Waiting for approval'
        },
        getUserContent(user){
            if(user?.approved_at && user.approved_by){
                return user.user_name +' approved'
            }else if(user?.rejected_at){
                return user.user_name +' rejected'
            }else if(this.currentFormbuilderData.approval_status == "REJECTED" || this.currentFormbuilderData.approval_status == "APPROVED"){
                return "Don't require any action"
            }else if(user.preferred_company_users){
                return 'Waiting for ' + user.preferred_company_users.join(", ") +' approval'
            }else if(user.preferred_application_users){
                return 'Waiting for ' + user.preferred_application_users.join(", ") +' approval'
            }
            return 'Waiting for approval'
        },
        getInitialNames(name) {
            let result = "";
            if (name) {
                result = name[0];
                if(name.includes("-")){
                    result += name.split("-")[1][0];
                }
                else if (name.split(" ")?.[1]?.[0]) {
                    result += name.split(" ")[1][0];
                }
            }
            return result.toUpperCase();
        },
        getBasedOnFieldData(user, i) {
            if(this.currentFormbuilderData?.formbuilder_steps_data && user?.approval_linked_field){
                let entity = user.approval_linked_field.split("#");
                if(entity && entity[1] && this.entityNameObj[entity[1]]){
                    return this.entityNameObj[entity[1]]
                }
            }
            return "User " +i;
        }
    }
}
</script>
<style lang="scss" scoped>
.documentLogs__title {
    padding: 10px 15px;
}

.logs-avatar {
    display: flex !important;
    justify-content: space-between !important;

    .avatar-text {
        height: 35px;
        width: 35px;
        font-weight: 600;
        font-size: 1.1em;
        line-height: 35px;
        text-align: center;
        border-radius: 30px;
        padding: 0;
        background-color: #ffffff;
    }

    h5 {
        margin-top: 7px;
        margin-left: 7px;
    }
}

.logs-content {
    position: relative;
}
.iconStyling {
  margin-right: 4px;
  font-size: 13px;
}
.avatar-icon {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 3px;
}
.resendMail{
 margin-left: 220px;
 //color: #3c32a8;
 font-weight: 400;
}
.resendMailLoading{
  margin-left: 150px;
  font-size: 14px;
}
.scroll{
        scrollbar-width: thin;
        height:30vh;
        overflow-y: auto;   
}
.resendDiv{
    margin-bottom: 0%;
}
.logs-text{
  word-break: break-word;
}
</style>